import { ReactComponent as LogoBlackS } from "./icons/logo_sm_black.svg";
import { ReactComponent as LogoThailand } from "./icons/logo_thailand.svg";
import { ReactComponent as FaceBook } from "./icons/sns-facebook.svg";
import { ReactComponent as Line } from "./icons/sns-line.svg";
import { ReactComponent as BrandJaSolar2 } from "./icons/brand-jasolar2.svg";
import { ReactComponent as BrandJinkoSolar2 } from "./icons/brand-jinkosolar2.svg";
import { ReactComponent as BrandRisenEnergy } from "./icons/brand-risenenergy.svg";
import { ReactComponent as BrandRisenEnergy2 } from "./icons/brand-risenenergy2.svg";
import { ReactComponent as BrandTrinaSolar2 } from "./icons/brand-trinasolar2.svg";
import { ReactComponent as IconBusiness2 } from "./icons/icon-business2.svg";
import { ReactComponent as IconClose } from "./icons/icon-close.svg";
import { ReactComponent as IconDownload } from "./icons/icon-download.svg";
import { ReactComponent as IconLocation } from "./icons/icon-location.svg";
import { ReactComponent as IconMenu } from "./icons/icon-menu.svg";
import { ReactComponent as IconThinArrow } from "./icons/icon-thin-arrow.svg";
import { ReactComponent as IconWorker2 } from "./icons/icon-worker2.svg";
import { ReactComponent as IconCall3 } from "./icons/04-call3.svg";
import { ReactComponent as IconAccident2 } from "./icons/05-accident2.svg";
import { ReactComponent as SolarBg_en1 } from "./icons/solar_en_01.svg";
import { ReactComponent as SolarBg_en2 } from "./icons/solar_en_02.svg";
import { ReactComponent as SolarBg_en3 } from "./icons/solar_en_03.svg";
import { ReactComponent as SolarBg_en4 } from "./icons/solar_en_04.svg";
import { ReactComponent as SolarBg_en5 } from "./icons/solar_en_05.svg";
import { ReactComponent as It_1 } from "./icons/it_01.svg";
import { ReactComponent as It_2 } from "./icons/it_02.svg";
import { ReactComponent as It_3 } from "./icons/it_03.svg";
import { ReactComponent as It_4 } from "./icons/it_04.svg";
import { ReactComponent as It_5 } from "./icons/it_05.svg";
import { ReactComponent as It_6 } from "./icons/it_06.svg";

const Icons = {
  LogoBlackS,
  LogoThailand,
  FaceBook,
  Line,
  BrandJaSolar2,
  BrandJinkoSolar2,
  BrandRisenEnergy,
  BrandRisenEnergy2,
  BrandTrinaSolar2,
  IconDownload,
  IconThinArrow,
  IconLocation,
  IconClose,
  IconMenu,
  IconBusiness2,
  IconWorker2,
  IconCall3,
  IconAccident2,
  SolarBg_en1,
  SolarBg_en2,
  SolarBg_en3,
  SolarBg_en4,
  SolarBg_en5,
  It_1,
  It_2,
  It_3,
  It_4,
  It_5,
  It_6,
};

export default Icons;
