import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import Icons from "../assets/icons";
import ScrollToTop from "../utils/ScrollToTop";
import { useEffect, useState } from "react";
interface widthInterface {
  w?: number;
}
const navList = [
  { to: "홈", translation: "home", path: "" },
  { to: "회사 소개", translation: "introduce", path: "introduce" },
  { to: "사업 영역", translation: "business", path: "business" },
  { to: "인재 채용", translation: "recruit", path: "recruit" },
];
const snsList = [
  {
    logo: <Icons.FaceBook />,
    to: "https://www.facebook.com/SNDonboard?mibextid=ZbWKwL",
  },
  {
    logo: <Icons.Line />,
    to: "https://liff.line.me/1645278921-kWRPP32q/?accountId=scgsth",
  },
  {
    logo: <Icons.IconCall3 />,
    to: "tel:02-119-7570",
  },
];
const HeadNavStyle = styled.header<widthInterface>`
  position: sticky;
  z-index: 2;
  top: 0;
  left: 0;
  color: ${(props) => props.color || "white"};
  width: 100%;
  background-color: #fff;
  white-space: nowrap;
  font-size: 16px;
  .inner-box {
    display: flex;
    margin: auto;
    width: 95%;
    max-width: 1200px;
    > li {
      min-width: 100px;
      transition: 0.3s;
      display: flex;
      height: 50px;
      position: relative;
      color: #000;
      :hover nav {
        height: 34px;
      }
      :nth-of-type(1) {
        min-width: 160px;
        margin-right: auto;
      }
      nav {
        transition: 0.3s;
        position: absolute;
        top: 100%;
        height: 0px;
        transform: translateX(-30%);
        overflow: hidden;
        color: #000000;
        ul {
          display: flex;
          background: #ffffffbb;
          overflow: hidden;
          li {
            padding: 5px 20px;
            h1 {
              font-weight: normal;
            }
          }
          span {
            padding: 13px 20px;
            white-space: nowrap;
            margin: auto;
          }
        }
        li {
          transition: 0.3s;
          padding: 13px 20px;
          white-space: nowrap;
          margin: auto;
          position: relative;
          :hover {
            background: #eeeeeebb;
            color: #000000;
          }
        }
      }

      &:first-child p {
        width: 100%;
        height: 100%;
        svg {
          margin: auto;
          width: 100%;
          height: 70%;
        }
      }
      &.active {
        background: #2d59a2;
        color: #fff;
        &:hover {
          transition: 0.1s;
          background: #3e73c8;
        }
        &:active {
          transition: 0.1s;
          background: #20447f;
        }
      }
      img {
        vertical-align: bottom;
        margin-right: 10px;
      }
      p {
        margin: auto;
        display: flex;
        svg {
        }
      }
      &:hover {
        transition: 0.1s;
        background: #eee;
      }
      &:active {
        transition: 0.1s;
        background: #ddd;
      }
    }
    .sns-list {
      font-size: 0;
      display: flex;
      min-width: 200px;
      width: 200px;
      transition: 0.3s;
      margin-left: 15px;
      .logo-area {
        position: relative;
        margin: auto;
        border-radius: 100px;
        display: flex;
        p {
          margin: auto 5px;
          font-size: 16px;
          color: black;
        }
        path,
        circle,
        rect {
          transition: 0.3s;
        }
        :hover {
          path,
          circle,
          rect {
            fill: #fff;
          }
          g path {
            fill: #032b79;
          }
        }
      }
    }
  }
  a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .closeButton {
    display: none;
  }
  .translation {
    height: 30px;
    aspect-ratio: 1;
    position: absolute;
    border-radius: 100px;
    top: 60px;
    right: 10px;
    background: #00226655;
    display: flex;
    transition: 0.3s;
    &:hover {
      background: #002266;
      height: 50px;
    }
    p {
      margin: auto;
      line-height: 1em;
    }
  }
  @media (max-width: 1024px) {
    .inner-box {
      width: 100vw;
    }
  }
  @media (max-width: 768px) {
    position: absolute;
    .inner-box {
      flex-direction: column;
      li:nth-of-type(1) {
        display: flex;
        height: 50px;
        background: unset;
      }
      li:nth-of-type(1).active {
        background: #00000066;
      }
      > li {
        width: 100%;
        height: 0px;
        overflow: hidden;
        nav {
          ul {
            background: #fff;
          }
        }
      }
      .sns-list {
        min-width: unset;
        width: unset;
        margin-left: unset;
        overflow: hidden;
        height: 0;
        .logo-area {
          margin: auto;
          height: auto;
          justify-content: center;
          p {
            display: none;
          }
        }
      }
    }
    &.active {
      width: 100%;
      li {
        height: 50px;
        width: 100%;
        &.business-nav {
          display: flex;
          flex-direction: column;
          height: 150px;
          nav {
            position: relative;
            height: 100px;
            transition: unset;
            transform: unset;
            top: unset;
            overflow: unset;
            ul {
              text-align: center;
              width: 100%;
              flex-wrap: wrap;
              li {
                display: flex;
                h1 {
                  margin: auto;
                }
              }
            }
          }
        }
      }
      .closeButton {
        transition: 0.3s;
      }
      .translation {
        display: flex;
        height: 50px;
        aspect-ratio: unset;
        position: unset;
        border-radius: unset;
        top: unset;
        right: unset;
        background: #fff;
        color: #000;
        p::before {
          content: "language change : ";
        }
      }
      .sns-list {
        height: 50px;
      }
    }
    .closeButton {
      display: flex;
      background: #fff;
      width: 50px;
      transition: 0.3s;
      overflow: hidden;
      margin-left: auto;
      position: absolute;
      right: 0;
      &:hover {
        transition: 0.1s;
        background: #eee;
      }
      &:active {
        transition: 0.1s;
        background: #ddd;
      }
      svg {
        transition: 0.3s;
        margin: auto;
      }
    }
    .translation {
      display: none;
    }
  }
`;
const HeadNav = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const path2 = location.pathname.split("/")[2];
  const path3 = location.pathname.split("/")[3];
  const pathIdCheck = location.pathname.split("/")[2];
  const [isPopup, setIsPopup] = useState(false);

  useEffect(() => {
    return () => {
      ScrollToTop();
    };
  }, [path, path2, path3]);
  return (
    <HeadNavStyle
      className={`no-select ${isPopup ? "active" : ""}`}
      w={navList.length}
    >
      <ul className="inner-box">
        {navList.map((res, idx) => {
          return (
            <li
              onClick={() => setIsPopup(false)}
              key={idx}
              className={`${
                path === `${res.path}` && res.translation !== "home"
                  ? "active"
                  : ""
              } ${res.path === `business` ? "business-nav" : ""}`}
            >
              <p>
                {res.translation === "home" ? (
                  <Icons.LogoThailand />
                ) : (
                  res.translation
                )}
              </p>
              {path !== `${res.path}` || pathIdCheck ? (
                <Link
                  to={{
                    pathname: `/${idx !== 2 ? res.path : `${res.path}/it`}`,
                  }}
                ></Link>
              ) : (
                <></>
              )}
              {res.path === `business` ? (
                <nav>
                  <ul>
                    <li>
                      <h1>IT</h1>
                      <Link to={"/business/it"} />
                    </li>
                    <li>
                      <h1>Renewable Energy</h1>
                      <Link to={"/business/solar"} />
                    </li>
                  </ul>
                </nav>
              ) : (
                ""
              )}
            </li>
          );
        })}
        <ul className="sns-list">
          {snsList.map((res, idx) => {
            return (
              <li className="logo-area" key={idx}>
                {res.logo}
                <Link
                  target={idx !== 2 ? "_blank" : ""}
                  to={idx !== 2 ? res.to : "tel:02-119-7570"}
                ></Link>
                {idx === 2 && <p>02-119-7570</p>}
              </li>
            );
          })}
        </ul>
        <div
          className="closeButton"
          onClick={() => (isPopup ? setIsPopup(false) : setIsPopup(true))}
        >
          {isPopup ? <Icons.IconClose /> : <Icons.IconMenu />}
        </div>
      </ul>
    </HeadNavStyle>
  );
};

export default HeadNav;
