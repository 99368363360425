import React from "react";
import SCGSRouter from "./Routes";
import WindowHeight from "./utils/WindowHeight";

function App() {
  WindowHeight();
  return (
    <React.StrictMode>
      <SCGSRouter />
    </React.StrictMode>
  );
}

export default App;
