import styled from "styled-components";

const NotFoundStyle = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  font-weight: bold;
  text-align: center;
  background-image: url("/intro.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  div.not-found-box {
    display: flex;
    flex-direction: column;
    margin: auto;
    img {
      margin: auto;
    }
    span {
      margin: 40px 0;
    }
    p {
      border: 1px solid #aaa;
      transition: 0.3s;
      padding: 10px 20px;
      cursor: pointer;
      &:hover {
        background: #eee;
        transition: 0.1s;
      }
      &:active {
        background: #ccc;
        transition: 0.1s;
      }
    }
  }
`;

const NotFound = () => {
  return (
    <NotFoundStyle className="no-select">
      <div className="not-found-box">
        <img src="/logo_color.svg" alt="" srcSet="" />
        <span>Not found, try access in normal route.</span>
        <p
          onClick={() => {
            window.history.back();
          }}
        >
          back
        </p>
      </div>
    </NotFoundStyle>
  );
};
export default NotFound;
