import { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Icons from "../assets/icons";
interface dataProps {
  main: string;
  hover: string;
  active: string;
  background: string;
}

const data = {
  head: {
    title: "JA Solar l JinKo Solar l Risen Solar Technology l Trina Solar",
    color: {
      main: "#ff9900",
      hover: "#ffa621",
      active: "#bd7100",
      background: "#fffefc",
    },
    type: "solar",
    description: [
      "SCGS is The Official partner of global leading manufactures",
      "JA Solar l JinKo Solar l Risen Solar Technology l Trina Solar",
    ],
  },
  item: [
    {
      title: "Module",
      itemImg: <div style={{ backgroundImage: "url('/icons/solar-1.png')" }} />,
      itemList: [
        {
          brand: <Icons.BrandJaSolar2 />,
          brandList: [
            {
              type: "DS",
              name: "JAM72D30-550/MB",
              fileLink:
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/01+JA+solar/1.+JAM72D30-545MB(%EC%96%91%EB%A9%B4)/550MB_en.pdf",
            },
            {
              type: "DS",
              name: "JAM78D30-595/MB",
              fileLink:
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/01+JA+solar/2.+JAM78D30-590MB(%EC%96%91%EB%A9%B4)/595MB_en.pdf",
            },
            {
              type: "DS",
              name: "JAM78D40-615/GB",
              fileLink:
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/01+JA+solar/3.+JAM78D40-610GB(%EC%96%91%EB%A9%B4)/615GB_en.pdf",
            },
          ],
        },
        {
          brand: <Icons.BrandJinkoSolar2 />,
          brandList: [
            {
              type: "SS",
              name: "JKM550N-72HL4-V",
              fileLink:
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/02+Jinko+korea/1.+JKM555N(~575N)-72HL4-V.pdf",
            },
            {
              type: "SS",
              name: "JKM620N-78HL4-V",
              fileLink:
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/02+Jinko+korea/2.+JKM610N(~620N)-78HL4-V.pdf",
            },
            {
              type: "DS",
              name: "JKM550N-72HL4-BDV",
              fileLink:
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/02+Jinko+korea/3.+JKM560N(~580N)-72HL4-BDV.pdf",
            },
            {
              type: "DS",
              name: "JKM620N-78HL4-BDV",
              fileLink:
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/02+Jinko+korea/4.+JKM610N(630N)-78HL4-BDV.pdf",
            },
          ],
        },
        {
          brand: <Icons.BrandRisenEnergy2 />,
          brandList: [
            {
              type: "SS",
              name: "RSM110-8-550M",
              fileLink:
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/03+Risen+korea/1.+RSM110-8-550M(%EC%98%81%EB%AC%B8).pdf",
            },
            {
              type: "SS",
              name: "RSM132-8-650-670M",
              fileLink:
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/03+Risen+korea/RSM132-8-655-675M+IEC1500V-35mm+2023H1-1-EN.pdf",
            },
            {
              type: "DS",
              name: "RSM110-8 -545BMDG",
              fileLink:
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/03+Risen+korea/3.+RSM110-8-545BMDG(%EC%98%81%EB%AC%B8).pdf",
            },
            {
              type: "DS",
              name: "RSM132-8-650-670BMDG",
              fileLink:
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/03+Risen+korea/RSM132-8-650-670BMDG++IEC1500V-33mm+2023H1-2-EN.pdf",
            },
            {
              type: "DS",
              name: "RSM156-9-605-615BNDG",
              fileLink:
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/03+Risen+korea/RSM156-9-605-625BNDG+IEC1500V-30mm+2023H1-1-EN(2).pdf",
            },
          ],
        },
        {
          brand: <Icons.BrandTrinaSolar2 />,
          brandList: [
            {
              type: "DS",
              name: "TSM-595DEG20C.20",
              fileLink:
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/04+Trina+solar/1.+TSM-595DEG20C.20(%EC%98%81%EB%AC%B8).pdf",
            },
            {
              type: "DS",
              name: "TSM-655DE21",
              fileLink:
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/04+Trina+solar/2.+TSM-655DE21(%EC%98%81%EB%AC%B8%2C+660%ED%8F%AC%ED%95%A8).pdf",
            },
            {
              type: "DS",
              name: "TSM-660DE21",
              fileLink:
                "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EB%AA%A8%EB%93%88/04+Trina+solar/2.+TSM-655DE21(%EC%98%81%EB%AC%B8%2C+660%ED%8F%AC%ED%95%A8).pdf",
            },
          ],
        },
      ],
    },
  ],
};
const data2 = {
  title: "Completion",
  itemList: [
    {
      brand: (
        <div
          style={{
            backgroundImage: "url('/icons/history-true.svg')",
            margin: "10px auto",
          }}
        />
      ),
      name: ["Provide 410W", "Risen Energy"],
      itemImg: (
        <div style={{ backgroundImage: "url('/icons/solar-2-1.jpg')" }} />
      ),
      brandList: ["Provide Tier 1 solar PV", "Installation service phase 1&2"],
    },
    {
      brand: (
        <div
          style={{
            backgroundImage: "url('/icons/history-telecom.png')",
            margin: "12px auto",
          }}
        />
      ),
      name: ["Provide 540W", "JA Solar PV"],
      itemImg: (
        <div style={{ backgroundImage: "url('/icons/solar-2-2.jpg')" }} />
      ),
      brandList: ["Provide Tier 1 solar PV"],
    },
  ],
};

const data3 = {
  title: "solar_3_title",
  description: "solar_3_title_des",
  itemImg: <div style={{ backgroundImage: "url('/icons/dell-1-1.png')" }} />,
  itemList: [
    {
      info: <Icons.SolarBg_en1 />,
      itemSubList: [
        "solar_3_con_1_1",
        "solar_3_con_1_2",
        "solar_3_con_1_3",
        "solar_3_con_1_4",
        "solar_3_con_1_5",
      ],
    },
    {
      info: <Icons.SolarBg_en2 />,
      itemSubList: [
        "solar_3_con_2_1",
        "solar_3_con_2_2",
        "solar_3_con_2_3",
        "solar_3_con_2_4",
        "solar_3_con_2_5",
        "solar_3_con_2_6",
      ],
    },
    {
      info: <Icons.SolarBg_en3 />,
      itemSubList: [
        "solar_3_con_3_1",
        "solar_3_con_3_2",
        "solar_3_con_3_3",
        "solar_3_con_3_4",
        "solar_3_con_3_5",
        "solar_3_con_3_6",
      ],
    },
    {
      info: <Icons.SolarBg_en4 />,
      itemSubList: [
        "solar_3_con_4_1",
        "solar_3_con_4_2",
        "solar_3_con_4_3",
        "solar_3_con_4_4",
        "solar_3_con_4_5",
      ],
    },
    {
      info: <Icons.SolarBg_en5 />,
      itemSubList: [
        "solar_3_con_5_1",
        "solar_3_con_5_2",
        "solar_3_con_5_3",
        "solar_3_con_5_4",
      ],
    },
  ],
};

const BusinessSolarStyle = styled.ul`
  .scroll {
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    position: relative;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const BusinessSolarMainStyle = styled.div`
  .scroll {
    min-height: 600px;
  }
  .banner-image {
    background: url("/background/business-solar.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    .banner-text {
      margin: auto;
      background: #ffffff99;
      color: #000;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      h1 {
        font-size: 60px;
        margin: auto;
        line-height: 1em;
      }
    }
  }
  @media (max-width: 768px) {
    .scroll {
      min-height: 400px;
    }
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const BusinessSolarMain = () => {
  return (
    <BusinessSolarMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleX(0)", opacity: 0 }}
          animate={{ transform: "scaleX(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            Renewable Energy
          </motion.h1>
        </motion.div>
      </div>
    </BusinessSolarMainStyle>
  );
};

const BusinessItMainStyle = styled.div<dataProps>`
  .scroll {
    max-width: 1200px;
    width: 95%;
    margin: 30px auto;
  }
  .nav-header {
    height: 60px;
    font-size: 26px;
    font-weight: bold;
    color: ${(props) => props.main};
    text-align: center;
    transition: 0.3s;
    .main-nav {
      height: 100%;
      display: flex;
      border-bottom: 2px solid ${(props) => props.main};
      transition: inherit;
      li {
        transition: inherit;
        width: 100%;
        height: 100%;
        display: flex;
        p {
          margin: auto;
        }
        &.active {
          background-color: ${(props) => props.main};
          color: #fff;
        }
      }
    }
  }
  .main-contents {
    text-align: center;
    .main-contents-body {
      > ul {
        display: flex;
        flex-wrap: wrap;
        margin: auto;
        max-width: 800px;
        .main-contents-item {
          width: calc(100% / 2);
          display: flex;
          flex-direction: column;
          padding: 10px;
          transition: 0.3s;
          .icon-logo {
            height: 50px;
            display: flex;
            div {
              width: 100%;
              height: auto;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }
          }
          :hover {
            transform: translate(-2px, -2px);
            box-shadow: 2px 2px 5px 0 #00000022;
            .contents-img {
              .background {
                opacity: 0;
              }
            }
          }
          .contents-img {
            position: relative;
            .background {
              transition: 0.3s;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: #ff9900cc;
              display: flex;
              flex-direction: column;
              justify-content: center;
              h2 {
                position: relative;
                color: #fff;
                z-index: 1;
              }
            }
            div {
              aspect-ratio: 1;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
          .contents-description-ul {
            margin-top: 15px;
            li {
              display: flex;
              text-align: left;
              p {
                display: flex;
                font-size: 16px;
              }
              ::before {
                content: "· ";
                font-weight: bold;
                margin: 0px 10px 0px 0px;
                text-indent: 15px;
              }
            }
          }
          h2 {
            font-size: 22px;
          }
          p {
            font-size: 16px;
            margin-bottom: auto;
          }
        }
      }
      h1 {
        font-size: 30px;
        color: ${(props) => props.main};
        display: flex;
        border-bottom: 1px solid ${(props) => props.main};
        ::before {
          content: "";
          width: 6px;
          height: 22px;
          margin: auto 7px auto 0;
          background-color: ${(props) => props.main};
        }
        aside {
          margin: auto 5px auto auto;
          padding: 0 10px;
          font-size: 14px;
          font-weight: 400;
          border: 1px solid ${(props) => props.main};
          transition: 0.3s;
          position: relative;
          ::after {
            transition: inherit;
            content: "";
            position: absolute;
            width: 0%;
            height: 100%;
            background-color: ${(props) => props.main};
            top: 0;
            left: 0;
            z-index: 0;
          }
          :hover {
            ::after {
              width: 100%;
            }
            p {
              color: #fff;
            }
          }
          p {
            position: relative;
            transition: inherit;
            margin: auto;
            z-index: 1;
          }
        }
      }
      .module-area {
        margin: 50px auto;
        max-width: 1200px;
        display: flex;
        .contents-img {
          min-width: 200px;
          margin: auto 20px;
          div {
            aspect-ratio: 2/3;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
        .module-ul {
          display: flex;
          width: 100%;
          position: relative;
          &.en-comment {
            ::before {
              bottom: 0;
              right: 0;
              font-size: 12px;
              position: absolute;
              color: #888;
              content: "SS: Single Side / DS: Double Side";
            }
          }
          .main-contents-item {
            width: 100%;
            margin-right: 10px;
            .list-area {
              text-align: left;
              font-size: 14px;
              li {
                display: flex;
                background-color: #f4f4f4;
                margin-bottom: 5px;
                transition: 0.3s;
                :hover {
                  transform: translate(-2px, -2px);
                  box-shadow: 2px 2px 5px 0 #00000022;
                }
                .module-type {
                  min-height: 50px;
                  height: inherit;
                  min-width: 40px;
                  display: flex;
                  color: #fff;
                  background-color: #ff9900;
                  font-weight: bold;
                  span {
                    margin: auto;
                  }
                }
                .module-name {
                  padding: 5px 10px;
                  align-self: center;
                }
                .module-icon {
                  display: flex;
                  position: relative;
                  margin-left: auto;
                  svg {
                    margin: auto;
                    min-width: 40px;
                    path {
                      fill: #ff9900;
                    }
                  }
                  a {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  a {
    z-index: 1;
  }

  @media (max-width: 1024px) {
    .main-contents {
      .main-contents-body {
        .module-area {
          display: unset;
          .contents-img {
            min-width: unset;
            margin: auto;
            max-width: 200px;
          }
          .module-ul {
            display: unset;
            &.en-comment {
              ::before {
                top: 0;
                content: "SS: Single Side / DS: Double Side";
              }
            }
            .main-contents-item {
              max-width: 600px;
              margin: auto;
              .list-area {
                li {
                  .module-type {
                    min-width: 50px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .main-contents {
      .main-contents-body {
        > ul {
          pointer-events: none;
          justify-content: center;
          .main-contents-item {
            width: calc(100%);
            max-width: 400px;
            .contents-img {
              display: flex;
              .background {
                width: 50%;
                position: relative;
                aspect-ratio: 1;
                background-color: #ff9900;
                /* background-color: #2d59a2; */
                h2 {
                  padding: 0 10px;
                  line-height: 1.1em;
                  font-size: 16px;
                }
              }
              > div {
                width: 50%;
              }
            }
          }
        }
        h1 {
          font-size: 20px;
        }
        .module-area {
          display: unset;
          .contents-img {
            min-width: unset;
            margin: auto;
            max-width: 200px;
          }
          .module-ul {
            display: unset;
            .main-contents-item {
              .list-area {
                li {
                  .module-type {
                    min-width: 50px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const BusinessItMain = () => {
  return (
    <BusinessItMainStyle
      main={data.head.color.main}
      active={data.head.color.active}
      hover={data.head.color.hover}
      background={data.head.color.background}
    >
      <div className="scroll">
        <section className="main-contents">
          <article className="main-contents-body">
            <h1>
              <p>{data.item[0].title}</p>
            </h1>
            <div className="module-area">
              <div className="contents-img">{data.item[0].itemImg}</div>
              <ul className={`module-ul en-comment`}>
                {data.item[0].itemList.map((res: any, idx) => {
                  return (
                    <li key={idx} className="main-contents-item">
                      <h2>{res.brand}</h2>
                      <ul className="list-area">
                        {res.brandList.map((res: any, idx: number) => {
                          return (
                            <li key={idx}>
                              <p className="module-type">
                                <span>{res.type}</span>
                              </p>
                              <p className="module-name">{res.name}</p>
                              <p className="module-icon pointer">
                                <Icons.IconDownload />
                                <a
                                  href={res.fileLink}
                                  download
                                  target="_blank"
                                />
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </div>
          </article>
          <article className="main-contents-body">
            <ul className="example">
              {data2.itemList.map((res, idx) => {
                return (
                  <li key={idx} className="main-contents-item">
                    <div className="icon-logo">{res.brand}</div>
                    <div className="contents-img">
                      <span className="background">
                        <h2>{res.name[0]}</h2>
                        <h2>{res.name[1]}</h2>
                      </span>
                      <div>{res.itemImg}</div>
                    </div>
                    <ul className="contents-description-ul">
                      {res.brandList.map((res2, idx2) => {
                        return (
                          <li key={idx2}>
                            <p>{res2}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </article>
        </section>
      </div>
    </BusinessItMainStyle>
  );
};

const BusinessSolar = () => {
  const up1 = useRef(null);
  const options = {
    root: null,
    rootMargin: "-10%",
    threshold: 0,
  };
  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      e.map((f) => {
        return f.isIntersecting && f.target.classList.add("up");
      });
    }, options);
    [up1].forEach((ref) => ref.current && observer.observe(ref.current));
  });
  return (
    <>
      <BusinessSolarStyle className="no-select">
        <BusinessSolarMain />
        <BusinessItMain />
      </BusinessSolarStyle>
    </>
  );
};

export default BusinessSolar;
