import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import HeadNav from "../components/Header";
import MainComponent from "../components/MainComponent";
import Footer from "../components/Footer";
import Intro from "../components/Intro";
import Recruit from "../components/Recruit";
import BusinessSolar from "../components/BusinessSolar";
import BusinessItSolution from "../components/BusinessItSolution";
import Icons from "../assets/icons";
const AdminMainStyle = styled.div`
  position: relative;
  overflow-x: hidden;
  .body {
    display: flex;
    flex-direction: column;
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    height: calc(var(--vh, 1vh) * 100 - 50px);
    width: 100%;
    overflow-x: hidden;
    overflow-y: overlay;
  }
  > aside {
    width: 50px;
    aspect-ratio: 1;
    position: fixed;
    right: 10px;
    bottom: 10px;
    display: flex;
    background: #ffffffaa;
    transition: 0.3s;
    opacity: 0;
    :hover {
      background: #eeeeee99;
    }
    :active {
      background: #eeeeee;
    }
    &.active {
      opacity: 1;
    }
    svg {
      width: 25px;
      height: 25px;
      margin: auto;
      rotate: -90deg;
      path {
        stroke: #026;
      }
    }
  }
  @media (max-width: 768px) {
    .body {
      overflow-y: overlay !important;
      margin-top: 50px;
      height: calc(var(--vh, 1vh) * 100 - 50px);
    }
  }
`;
const AdminMain = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const path2 = location.pathname.split("/")[2];
  const path3 = location.pathname.split("/")[3];
  const bodyRef = useRef<any>(null);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1,
    };
    let observer: any;
    setTimeout(
      () => (
        (observer = new IntersectionObserver((e) => {
          if (
            e[0].intersectionRatio === 1 &&
            window.document.querySelector(".banner-image")
          ) {
            window.document.querySelector("#aside")?.classList.remove("active");
          } else {
            window.document.querySelector("#aside")?.classList.add("active");
          }
        }, options)),
        [window.document.querySelector(".banner-image")].forEach(
          (ref) => ref && observer.observe(ref)
        )
      ),
      50
    );
  }, [location]);
  useEffect(() => {
    path === ""
      ? (bodyRef.current.style.overflowY = "hidden")
      : (bodyRef.current.style.overflowY = "overlay");
  }, [path]);

  return (
    <AdminMainStyle>
      <HeadNav />
      <div ref={bodyRef} className="body">
        {!path2 && (
          <>
            {path === "" && (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              >
                <MainComponent />
              </motion.div>
            )}

            {path === "introduce" && (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              >
                <Intro />
              </motion.div>
            )}

            {path === "recruit" && (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              >
                <Recruit />
              </motion.div>
            )}
          </>
        )}
        {path === "business" &&
          ((path2 === "it" && !path3 && <BusinessItSolution />) ||
            (path2 === "solar" && <BusinessSolar />))}
        <Footer />
      </div>
      <aside
        id="aside"
        onClick={() => {
          window.document
            .querySelector(".body")
            ?.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        {<Icons.IconThinArrow />}
      </aside>
    </AdminMainStyle>
  );
};

export default AdminMain;
