import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Icons from "../assets/icons";
import { Link } from "react-router-dom";
interface tabIndexInterface {
  idx?: number;
}
const HistoryData = [
  {
    year: "2023",
    activity: [
      "IT implementation service business launch",
      "Solar PV distribution business launch",
    ],
  },
  {
    year: "2017",
    activity: [
      "Operation outsource for FTTC network",
      "Digital Video solution implementation",
      "Network consulting business launch",
      "Excellent Service Provider Partner of the Year (Cisco)",
      "Implement Cisco DOCSIS solution (BB PH 10 project)",
    ],
  },
  {
    year: "2010",
    activity: ["SCG Solutions Thailand Ltd established"],
  },
];
const HistoryData2 = [
  {
    logo: "history-true.svg",
    p: "35px",
  },
  {
    logo: "history-kddi.svg",
    p: "35px",
  },
  {
    logo: "history-ppt-cth.png",
    p: "32px",
  },
  {
    logo: "history-3bb.svg",
    p: "35px",
  },
  {
    logo: "history-narit.svg",
    p: "25px",
  },
  {
    logo: "history-ppt-bbtec.png",
    p: "10px",
  },
  {
    logo: "history-nimt.png",
    p: "10px",
  },
  {
    logo: "history-telecom.png",
    p: "10px",
  },
  {
    logo: "history-ppt-kbjc.png",
    p: "10px",
  },
  {
    logo: "history-ppt-pacocasa.png",
    p: "15px",
  },
  {
    logo: "history-pti.png",
    p: "20px",
  },
];
const PartnersData = [
  {
    name: "SCG Solutions Co.,Ltd.",
    description:
      "SCG Solutions, an IT company specializing in Seoul Gas Group, is strategically securing global No.1 solutions and continuously expanding its business area through the establishment and investment of domestic and foreign corporations",
    category:
      "IT Solution Distributor, SI/ITO, Solar Module Supply, Service related to City Gas",
    address:
      "(05855) 5F, 11, Beobwon-ro 6-gil, Songpa-gu, Seoul, Republic of Korea",
    link: "https://www.scgs.co.kr/",
  },
  {
    name: "SCG Solutions Dalian Co.,Ltd.",
    description:
      "SCGS Dalian is promoting a telesales marketing business based on Call Outbound.",
    category: "Telesales Marketing/Sales",
    address:
      "306 Room No.10 Huoju road Hi-Tech Industrial zone Dalian Liaoning China",
  },
  {
    name: "Seoul City Gas",
    description:
      "Seoul City Gas is the first city gas supplier in Korea to supply gas upto 1 million tons and 1 million households, and is a key player of an energy comprehensive company, expanding its business to overseas resource development projects.",
    category: "Urban Gas Supply and Gas Supply Equipment Corporation",
    address:
      "607, Gonghang-daero, Gangseo-gu, Seoul (Yeomchang-dong, Seoul City Gas Company)",
    link: "https://www.seoulgas.co.kr/",
  },
  {
    name: "SCG Grid",
    description:
      "SCG Grid is the No. 1 industrial gas meter company (turbine and rotary) and is a meter manufacturer that develops and produces products related to domestic and industrial gas meters incorporating IoT technology.",
    category:
      "Manufacture and Sale of Domestic and Industrial Gas meter related products",
    address: "717-7 Gojan-dong, Namdong-gu, Incheon",
    link: "https://www.scggrid.com/",
  },
  {
    name: "SCG Lab",
    description:
      "SCGLab is a platform-specialized company that provides gas apps for city gas integrated solutions with the highest customer utilization and satisfaction among domestic public service apps to major city gas companies in Korea.",
    category:
      "Manufacture and Sale of Domestic and Industrial Gas meter related products",
    address: "6F, Inobase building, Worldcupbuk-ro 6-gil, Mapo-gu, Seoul",
    link: "http://www.scglab.com/",
  },
];
const IntroStyle = styled.ul`
  .scroll {
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    overflow: hidden;
    position: relative;
  }
`;

const IntroMainStyle = styled.div`
  .scroll {
    min-height: 600px;
  }
  .banner-image {
    background: #12363b;
    background: url("/background/intro-12.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    .banner-text {
      word-break: keep-all;
      margin: auto;
      background: #00000099;
      color: #fff;
      width: 100%;
      padding: 40px 0;
      text-align: center;
      h1 {
        line-height: 1.2em;
        font-size: 60px;
        max-width: 900px;
        margin: auto;
      }
      p {
        max-width: 720px;
        margin: 30px auto auto;
      }
    }
  }

  @media (max-width: 768px) {
    .scroll {
      min-height: 400px;
    }
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const IntroMain = () => {
  return (
    <IntroMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleY(0)", opacity: 0 }}
          animate={{ transform: "scaleY(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            Company
          </motion.h1>
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            SCG Solutions (Thailand) Ltd.
          </motion.h1>
        </motion.div>
      </div>
    </IntroMainStyle>
  );
};

const IntroHistoryStyle = styled.div<tabIndexInterface>`
  transition: 0.3s;
  background-image: ${(props) =>
    props.tabIndex === 1 &&
    `linear-gradient(to right, #ffffff66, #fff 50%),
    url("/background/intro-4.jpg")`};
  background-size: cover;
  .scroll {
    height: unset;
  }
  .info-frame {
    margin: 0 auto;
    height: inherit;
    display: flex;
    flex-direction: column;
    position: relative;
    :focus-visible {
      outline: none;
    }
    > * {
      z-index: 1;
    }
  }
  h1 {
    text-align: center;
    font-size: 40px;
  }
  .info-select {
    margin: 50px auto;
    max-width: 1200px;
    line-height: 1em;
    display: flex;
    width: 95%;
    border-bottom: 2px solid #2d59a2;
    color: #2d59a2;
    .button {
      font-size: 24px;
      font-weight: bold;
      min-width: 200px;
      width: 100%;
      text-align: center;
      height: 60px;
      display: flex;
      background: #fff;
      transition: 0.3s;
      p {
        margin: auto;
      }
    }
    .button.active {
      color: #fff;
      background: #2d59a2;
    }
  }
  .info-description {
    display: flex;
    flex-wrap: wrap;
    .info-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      .general-info {
        margin: 0 auto;
        width: 95%;
        section + section {
          margin: 50px 0;
        }
        .general-txt {
          background-color: #eee;
          padding: 20px 30px;
          h3 {
            margin-bottom: 20px;
          }
          p {
            & + p {
              margin-top: 10px;
            }
          }
        }
      }
      &.w50 {
        max-width: 800px;
        margin: auto;
        .history-li {
          width: 100%;
          display: flex;
          .history-li-year {
            display: flex;
            flex-direction: column;
            margin-right: 50px;
            h2 {
              font-size: 35px;
              line-height: 1em;
              text-align: start;
              color: #2d59a2;
              position: relative;
            }
          }
          .history-li-data {
            width: 100%;
            margin-bottom: 30px;
            li {
              font-size: 20px;
              padding: 5px 0;
            }
          }
        }
      }
      .partners-box {
        margin: 0 auto;
        width: 95%;
      }
    }
    .history-image-style {
      display: flex;
      width: 100%;
      margin-top: 50px;
      flex-direction: column;
      /* background-image: linear-gradient(to top, #eeeeeeff, #eeeeee00); */
      /* background: #000000aa; */
      background: #eeeeee;
      padding: 50px 0;
      .titleDesign {
        max-width: 1200px;
        width: 95%;
        margin: 30px auto;
      }
      .info-box {
        margin: 0 auto;
        flex-direction: unset;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1020px;
        .history-image {
          transition: 0.3s;
          box-shadow: 1px 1px 5px 0 #00000011;
          background: #ffffff;
          border-radius: 3px;
          padding: 5px;
          width: 100%;
          max-width: 150px;
          aspect-ratio: 3/2;
          display: flex;
          margin: 10px;
          span {
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
          :hover {
            transform: translate(1px, 1px);
            box-shadow: unset;
          }
        }
      }
    }
  }
  .partners-bottom {
    margin: 30px 0 70px;
    .partners-bottom-box {
      word-break: keep-all;
      display: flex;
      & {
        border-bottom: 2px solid transparent;
      }
      &:last-child {
        border-bottom: unset;
      }
      > h2 {
        min-width: 170px;
        width: 170px;
        background: #2d59a2;
        color: #fff;
        min-height: 50px;
        display: flex;
        border-radius: unset;
        justify-content: space-between;
        position: relative;
        span {
          margin: auto 0;
          padding: 0 10px;
        }
        b {
          width: 30px;
          aspect-ratio: 1;
          margin: auto 20px;
          font-size: 0;
          display: flex;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      > p {
        text-align: left;
        padding: 0 20px;
        display: flex;
        min-width: 220px;
        span {
          width: 100%;
          margin: auto 0;
        }
      }
      .button2 {
        width: fit-content;
        background-color: none;
        border: 1px solid #2d59a2;
        display: flex;
        height: fit-content;
        margin: auto 0;
        padding: 5px 20px;
        position: relative;
        transition: 0.3s;
        border-radius: 5px;
        ::after {
          content: "";
          position: absolute;
          z-index: -1;
          background-color: #2d59a2;
          width: 0%;
          height: 100%;
          left: 0%;
          top: 0%;
          transition: 0.3s;
        }
        :hover {
          color: #fff;
          ::after {
            content: "";
            position: absolute;
            z-index: -1;
            background-color: #2d59a2;
            width: 100%;
            height: 100%;
            left: 0%;
            top: 0%;
          }
          svg {
            path {
              fill: #fff;
            }
          }
        }
        p {
          padding: 0;
          margin: auto;
          display: flex;
          svg {
            path {
              fill: #000;
              transition: 0.3s;
            }
          }
        }
      }
      .qr-code {
        padding: 1px 5px;
        border: 1px solid #2d59a2;
        font-size: 12px;
        display: inline-block;
        width: fit-content;
        position: relative;
        margin-left: 5px;
        transition: 0.3s;
        &::before,
        &::after {
          content: "";
          transition: 0.3s;
          position: fixed;
          width: 0px;
        }
        &::before {
          position: absolute;
          background-color: #2d59a2;
          top: 0;
          left: 0;
          width: 0%;
          height: 100%;
          z-index: -1;
        }
        &:hover {
          color: #fff;
          &::before {
            width: 100%;
          }
        }
        &::after {
          aspect-ratio: 1;
          background-color: #2d59a2;
          background-image: url("./icons/qrcode.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 98px;
        }
        &.active::after {
          width: 100px;
        }
      }
    }
  }
  .contact-box {
    width: 100%;
    overflow: overlay;
    display: flex;
    flex-direction: column;
    .contact-location {
      display: flex;
      margin-right: 5px;
      svg {
        max-height: 20px;
        width: 20px;
        margin: auto;
        path {
          fill: #2d59a2;
        }
      }
    }
    div {
      margin-top: 10px;
    }
    p {
      display: flex;
      font-size: 16px;
      padding: 5px 0;
    }
  }
  .divider-v {
    height: 100%;
    padding: 5px 0;
    &::after {
      content: "";
      display: flex;
      margin: 0px auto;
      width: 1px;
      height: 100%;
      background: #2d59a2;
    }
  }
  .titleDesign {
    font-size: 30px;
    color: #2d59a2;
    display: flex;
    border-bottom: 1px solid #2d59a2;
    margin-bottom: 30px;
    ::before {
      content: "";
      width: 6px;
      height: 22px;
      margin: auto 7px auto 0;
      background-color: #2d59a2;
    }
    aside {
      margin: auto 5px auto auto;
      padding: 3px 10px;
      font-size: 14px;
      font-weight: 400;
      border: 1px solid #2d59a2;
      transition: 0.3s;
      position: relative;
      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      ::after {
        transition: inherit;
        content: "";
        position: absolute;
        width: 0%;
        height: 100%;
        background-color: #2d59a2;
        top: 0;
        left: 0;
        z-index: 0;
      }
      :hover {
        ::after {
          width: 100%;
        }
        p {
          color: #fff;
        }
      }
      p {
        position: relative;
        transition: inherit;
        margin: auto;
        z-index: 1;
      }
    }
  }
  @media (max-width: 768px) {
    h1 {
      font-size: 25px;
    }
    .info-select {
      margin: 30px auto;
      width: 95%;
      .button {
        height: 36px;
        min-width: unset;
        font-size: 16px;
      }
    }
    .info-description {
      display: unset;
      .info-box {
        margin: 30px 0;
        width: 100%;
        min-width: unset;
        &.w50 {
          width: 95%;
          max-width: 450px;
          .history-li {
            .history-li-year {
              margin-right: 20px;
              h2 {
                font-size: 20px;
              }
            }
            .history-li-data {
              li {
                font-size: 14px;
              }
            }
          }
        }
        &.history-image-style {
          min-width: unset;
          width: 100%;
          .history-image {
            margin: 5px 0;
          }
        }
      }
    }
    .partners-bottom {
      .partners-bottom-box {
        > h2 {
          width: 120px;
          min-width: 120px;
        }
        .qr-code::after {
          right: 10px;
          z-index: 1;
        }
      }
    }

    .contact-box {
      margin: 10px auto;
      p {
        font-size: 14px;
        .contact-location {
          width: 20px;
          svg {
            margin-top: 3px;
          }
        }
      }
    }
    .titleDesign {
      font-size: 20px;
      aside {
        font-size: 10px;
      }
    }
  }
`;
const IntroHistory = () => {
  const [tabIdx, setTabIdx] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <IntroHistoryStyle tabIndex={tabIdx}>
      <div className="scroll">
        <div
          className={`info-frame`}
          style={{ maxWidth: `${tabIdx !== 1 ? "1200px" : ""}` }}
        >
          <div
            className="info-select"
            style={{ maxWidth: `${tabIdx !== 1 ? "" : "1140px"}` }}
          >
            <div
              onClick={() => setTabIdx(0)}
              className={`button pointer ${tabIdx === 0 && "active"}`}
            >
              <p>General Information</p>
            </div>
            <div
              onClick={() => setTabIdx(1)}
              className={`button pointer ${tabIdx === 1 && "active"}`}
            >
              <p>History</p>
            </div>
            <div
              onClick={() => setTabIdx(2)}
              className={`button pointer ${tabIdx === 2 && "active"}`}
            >
              <p>Affiliates</p>
            </div>
          </div>
          <section className="info-description">
            {tabIdx === 0 && (
              <ul className={`info-box`}>
                <li className="general-info">
                  <motion.section
                    initial={{ opacity: 0, y: 150 }}
                    animate={{ opacity: 1, y: 0 }}
                  >
                    <h2 className="titleDesign">About us</h2>
                    <article className="general-txt">
                      <h3>
                        SCG Solutions Thailand which was established in 2010,
                        mainly supplies IT Products with Consulting Service and
                        runs the Solar Business
                      </h3>
                      <p>
                        The main business area of SCG Solutions Thailand, is the
                        Consulting Service, ITO, SI and PV Distribution which is
                        related with the Solar business
                      </p>
                      <p>
                        Now we are expanding to our business area by strategic
                        alliance with the global leading vendors and sales
                        partners in Thailand.
                      </p>
                    </article>
                    <article>
                      <div className="partners-bottom">
                        <div className="partners-bottom-box">
                          <h2>
                            <span>Establishment</span>
                          </h2>
                          <p>
                            <span>December 2010</span>
                          </p>
                        </div>
                        <div className="partners-bottom-box">
                          <h2>
                            <span>Location</span>
                          </h2>
                          <p>
                            <span>
                              6/10 Soi Patanasin, Pipatanasin Building, 10th
                              Floor, Narathiwas Ratchanakharin Road,
                              Thungmahamek, Sathorn, Bangkok 10120.
                            </span>
                          </p>
                        </div>
                        <div className="partners-bottom-box">
                          <h2>
                            <span>Contact</span>
                          </h2>
                          <p>
                            <span>
                              <a href="tel:66-2-119-7570">
                                Tel : 66-2-119-7570{" "}
                              </a>
                              / Fax : 66-2-119-7571 / QR Code :{" "}
                              <span
                                className={`${
                                  isOpen ? "active" : ""
                                } qr-code pointer`}
                                onClick={() => setIsOpen(!isOpen)}
                              >
                                QR Code
                              </span>
                            </span>
                          </p>
                        </div>
                        <div className="partners-bottom-box">
                          <h2>
                            <span>E-mail</span>
                          </h2>
                          <p style={{ position: "relative" }}>
                            <span>
                              <a href="mailto:Sales@scgs.co.th">
                                Sales@scgs.co.th
                              </a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </article>
                  </motion.section>
                  <motion.section
                    initial={{ opacity: 0, y: 150 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                  >
                    <h2 className="titleDesign">Location</h2>
                    <article className="contact-box">
                      <p>
                        <span className="contact-location">
                          <Icons.IconLocation />
                        </span>
                        <span>
                          195/5 Lake Rajada Office Complex Tower 2, 4th Floor,
                          Rajadapisek Road, Klongtoey Sub-district, Klongtoey
                          district, Bangkok 10110
                        </span>
                      </p>
                      <div>
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d242.23666745813384!2d100.56080346230341!3d13.731361121331455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29f1da16f2421%3A0x26818187bffbd6dc!2z66CI7J207YGsIOudvOywqOuLpCDsu7TtlIzroInsiqQ!5e0!3m2!1sko!2skr!4v1687844774925!5m2!1sko!2skr"
                          width="100%"
                          height="400"
                          style={{ border: 0 }}
                          allowFullScreen
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                    </article>
                  </motion.section>
                </li>
              </ul>
            )}
            {tabIdx === 1 && (
              <>
                <ul className={`info-box w50`}>
                  {HistoryData.map((res, idx) => {
                    return (
                      <motion.li
                        initial={{ opacity: 0, y: 150 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="history-li"
                        key={idx}
                      >
                        <div className="history-li-year">
                          <h2>{res.year}</h2>
                          {idx !== HistoryData.length - 1 && (
                            <p className="divider-v"></p>
                          )}
                        </div>
                        <ul className="history-li-data">
                          {res.activity.map((res2, idx2) => {
                            return <li key={idx2}>{res2}</li>;
                          })}
                        </ul>
                      </motion.li>
                    );
                  })}
                </ul>
                <div className={`history-image-style`}>
                  <h1 className="titleDesign">
                    <p>Customers</p>
                  </h1>
                  <motion.ul
                    initial={{ opacity: 0, y: 150 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                    className={`info-box`}
                  >
                    {HistoryData2.map((res, idx) => {
                      return (
                        <li
                          className="history-image"
                          style={{ padding: res.p }}
                          key={idx}
                        >
                          <span
                            style={{
                              backgroundImage: `url("icons/${res.logo}")`,
                            }}
                          ></span>
                        </li>
                      );
                    })}
                  </motion.ul>
                </div>
              </>
            )}
            {tabIdx === 2 &&
              PartnersData.map((res, idx) => {
                return (
                  <ul key={idx} className={`info-box`}>
                    <div className="partners-box">
                      <div className="inner-box">
                        <h1 className="titleDesign">
                          <p>{res.name}</p>
                          {res.link && (
                            <aside>
                              <p>Home page</p>
                              <Link target="_blank" to={res.link}></Link>
                            </aside>
                          )}
                        </h1>
                        <div className="partners-description">
                          <p>{res.description}</p>
                        </div>
                        <div className="partners-bottom">
                          <div className="partners-bottom-box">
                            <h2>
                              <span>Business</span>
                            </h2>
                            <p>
                              <span>{res.category}</span>
                            </p>
                          </div>
                          <div className="partners-bottom-box">
                            <h2>
                              <span>Location</span>
                            </h2>
                            <p>
                              <span>{res.address}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                );
              })}
          </section>
        </div>
      </div>
    </IntroHistoryStyle>
  );
};

const Intro = () => {
  const up1 = useRef(null);
  const up2 = useRef(null);
  const up3 = useRef(null);
  const options = {
    root: null,
    rootMargin: "-10%",
    threshold: 0,
  };
  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      e.map((f) => {
        return f.isIntersecting && f.target.classList.add("up");
      });
    }, options);
    up1.current && observer.observe(up1.current);
    up2.current && observer.observe(up2.current);
    up3.current && observer.observe(up3.current);
  });
  return (
    <IntroStyle className="no-select">
      <IntroMain />
      <section className="up-soon" ref={up2}>
        <IntroHistory />
      </section>
    </IntroStyle>
  );
};

export default Intro;
