import { useRef } from "react";
import Slider from "react-slick";
import styled from "styled-components";

const SlideStyle = styled.div`
  * {
    outline: none;
  }
  width: 100%;
  margin: auto;
  .slick-slider {
    display: flex;
    flex-direction: column;
    position: relative;
    .slick-list {
      overflow: hidden;
      .slick-track {
        display: flex;
        .slick-slide {
          font-size: 0;
        }
      }
    }
    .slick-dots {
      display: flex !important;
      margin: auto;
      position: absolute;
      bottom: -50%;
      right: 50%;
      transform: translate(50%);
      li {
        width: 10px;
        height: 10px;
        background: #ffffff44;
        border-radius: 100em;
        display: flex;
        margin: 10px 0;
        & + li {
          margin-left: 5px;
        }
        &.slick-active {
          background: #ffffffee;
        }
        button {
          font-size: 0;
          background: none;
          width: 100%;
          height: 100%;
          border: none;
          padding: 0;
          cursor: pointer;
        }
      }
    }
  }
`;
const SlideComponent = ({ children, settings }: any) => {
  const sliderRef = useRef<Slider>(null);

  return (
    <SlideStyle>
      <Slider ref={sliderRef} {...settings}>
        {children.map((res: any, idx: any) => {
          return <div key={idx}>{res}</div>;
        })}
      </Slider>
    </SlideStyle>
  );
};

export default SlideComponent;
