import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "./pages/Home";
import NotFound from "./pages/PageError";

const SCGSRouter = createBrowserRouter([
  { path: `/`, errorElement: <NotFound />, element: <Main /> },
  ...["introduce", "business", "recruit"].map((path) => ({
    path: `/${path}`,
    errorElement: <NotFound />,
    element: <Main />,
  })),
  {
    path: "/business/:type/:id?",
    element: <Main />,
    errorElement: <NotFound />,
  },
]);
const Router = () => {
  return <RouterProvider router={SCGSRouter} />;
};

export default Router;
