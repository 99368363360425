import { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Icons from "../assets/icons";

const dataConsulting = {
  itIcon: [
    {
      icon: <Icons.It_1 />,
    },
    {
      icon: <Icons.It_2 />,
    },
    {
      icon: <Icons.It_3 />,
    },
    {
      icon: <Icons.It_4 />,
    },
    {
      icon: <Icons.It_5 />,
    },
    {
      icon: <Icons.It_6 />,
    },
  ],
  item: [
    {
      name: "Network Evaluation via Datacraft (Dimension Data)",
      itemImg: (
        <div style={{ backgroundImage: "url('/icons/consulting-1-1.jpg')" }} />
      ),
      itemDescription: [
        "Docsis 1 Network Status",
        "Two-way Network Readiness",
        "Network Quality",
        "Check Upgradable Network ",
      ],
    },
    {
      name: "Network Audit for next phase",
      itemImg: (
        <div style={{ backgroundImage: "url('/icons/consulting-1-2.jpg')" }} />
      ),
      itemDescription: [
        "Check CMTS Configuration",
        "Recommendation",
        "HFC Network Audit",
        "Improvement and OJT",
        "Long-Distance Solution",
        "PoC, Training",
      ],
    },
    {
      name: "1Gbps showcase for True internet subscribers",
      itemImg: (
        <div style={{ backgroundImage: "url('/icons/consulting-1-3.jpg')" }} />
      ),
      itemDescription: [
        "In build Network Design",
        "Network Improvement",
        "PoC",
        "Field Trial with feedback",
      ],
    },
  ],
};
const dataItoSi = {
  description:
    "Provide Technical Services such as maintaining IT Infrastructure, System Monitoring and Integrate Hardware, Software, and Networks effectively based on specialized expertise and experience for customers",
  item: [
    {
      name: ["BB Project"],
      icon: (
        <div
          style={{
            backgroundImage: "url('/icons/history-true.svg')",
            margin: "12px auto",
          }}
        />
      ),
      itemImg: <div style={{ backgroundImage: "url('/icons/ito-1-1.jpg')" }} />,
      itemDescription: [
        "CMTS installation",
        "Configurations",
        "HFC product training",
        "Trouble shooting",
        "Training",
      ],
    },
    {
      name: ["DV solution", "Implementation"],
      icon: (
        <div
          style={{
            backgroundImage: "url('/icons/history-ppt-cth.png')",
            backgroundSize: "auto 60%",
          }}
        />
      ),
      itemImg: <div style={{ backgroundImage: "url('/icons/ito-1-2.jpg')" }} />,
      itemDescription: [
        "System design",
        "Installation and implementation",
        "Edge QAM installing 170 site",
        "PAT with each cable operation",
      ],
    },
    {
      name: ["FTTC MA service"],
      icon: (
        <div
          style={{
            backgroundImage: "url('/icons/history-true.svg')",
            margin: "12px auto",
          }}
        />
      ),
      itemImg: <div style={{ backgroundImage: "url('/icons/ito-1-3.jpg')" }} />,
      itemDescription: [
        "Network improvement",
        "Network maintenance",
        "Incident stand by 24x7",
        "As follow SLA",
      ],
    },
  ],
  item2: [
    {
      name: ["IPTV Platform with KT", "(Korea Telecom)"],
      icon: (
        <div
          style={{
            backgroundImage: "url('/icons/history-3bb.svg')",
            margin: "10px auto",
          }}
        />
      ),
      itemImg: <div style={{ backgroundImage: "url('/icons/ito-2-1.jpg')" }} />,
      itemDescription: ["3BB IPTV (Korea Telecom)", "Implementation"],
    },
    {
      name: ["Loan System Implementation"],
      icon: (
        <div
          style={{
            backgroundImage: "url('/icons/ppt-kb.png')",
            backgroundSize: "auto 130%",
          }}
        />
      ),
      itemImg: <div style={{ backgroundImage: "url('/icons/ito-2-2.jpg')" }} />,
      itemDescription: [
        "Provide Equipment",
        "Sever, Network Equipment, Storage and Software",
        "Implementation Service",
        "Operation Outsource",
      ],
    },
  ],
};
const BusinessItSolutionStyle = styled.ul`
  .scroll {
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    position: relative;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const BusinessItSolutionMainStyle = styled.div`
  .scroll {
    min-height: 600px;
  }
  .banner-image {
    background: url("/background/business-solution.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    .banner-text {
      margin: auto;
      background: #ffffff99;
      color: #000;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      h1 {
        font-size: 60px;
        line-height: 1em;
        margin: auto;
      }
    }
  }
  @media (max-width: 768px) {
    .scroll {
      min-height: 400px;
    }
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const BusinessItSolutionMain = () => {
  return (
    <BusinessItSolutionMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleX(0)", opacity: 0 }}
          animate={{ transform: "scaleX(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            IT Solution
          </motion.h1>
        </motion.div>
      </div>
    </BusinessItSolutionMainStyle>
  );
};

const BusinessItMainStyle = styled.div`
  .scroll {
    max-width: 1200px;
    width: 95%;
    margin: 50px auto 30px;
  }
  .main-contents {
    text-align: center;
    .main-contents-body {
      > ul {
        display: flex;
        flex-wrap: wrap;
        margin: auto;
        max-width: 1000px;
        padding: 20px 0;
        .main-contents-item {
          width: calc(100% / 3);
          display: flex;
          flex-direction: column;
          padding: 10px;
          transition: 0.3s;
          :hover {
            transform: translate(-2px, -2px);
            box-shadow: 2px 2px 5px 0 #00000022;
            .contents-img {
              .background {
                opacity: 0;
              }
            }
          }
          .contents-img {
            position: relative;
            .background {
              transition: 0.3s;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: #2d59a2cc;
              display: flex;
              flex-direction: column;
              justify-content: center;
              h2 {
                position: relative;
                color: #fff;
                z-index: 1;
              }
            }
            div {
              aspect-ratio: 1;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
          .contents-description-ul {
            margin-top: 15px;
            li {
              display: flex;
              text-align: left;
              p {
                display: flex;
                font-size: 16px;
              }
              ::before {
                content: "· ";
                font-weight: bold;
                margin: 0px 10px 0px 0px;
                text-indent: 15px;
              }
            }
          }
          h2 {
            font-size: 22px;
          }
          p {
            font-size: 16px;
            margin-bottom: auto;
          }
        }
      }
      .it-icon-ul {
        background-color: #f4f4f4;
        padding: 20px;
        width: 100%;
        max-width: unset;
        li {
          width: calc(100% / 6);
          display: flex;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .titleDesign {
    font-size: 30px;
    color: #2d59a2;
    display: flex;
    border-bottom: 1px solid #2d59a2;
    margin-bottom: 50px;
    ::before {
      content: "";
      width: 6px;
      height: 22px;
      margin: auto 7px auto 0;
      background-color: #2d59a2;
    }
  }
  .icon-logo {
    display: flex;
    height: 50px;
    div {
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  @media (max-width: 768px) {
    .main-contents {
      .main-contents-body {
        > ul {
          .main-contents-item {
            width: calc(100%);
            max-width: 400px;
            margin: auto;
            pointer-events: none;
            h2 {
              font-size: 18px;
            }
          }
          .main-contents-item {
            .contents-img {
              display: flex;
              .background {
                width: 50%;
                position: relative;
                aspect-ratio: 1;
                background-color: #2d59a2;
                h2 {
                  padding: 0 10px;
                  line-height: 1.1em;
                  font-size: 16px;
                }
              }
              > div {
                width: 50%;
              }
            }
          }
        }
        .it-icon-ul {
          padding: 20px;
          li {
            width: calc(100% / 3);
            max-width: 170px;
            margin: auto;
            svg {
            }
          }
        }
        h1 {
          font-size: 20px;
        }
      }
    }
  }
`;
const BusinessItMain = () => {
  return (
    <BusinessItMainStyle>
      <div className="scroll">
        <section className="main-contents">
          <article className="main-contents-body">
            <h1 className="titleDesign">Consulting Service</h1>
            <ul className="it-icon-ul">
              {dataConsulting.itIcon.map((res, idx) => {
                return <li key={idx}>{res.icon}</li>;
              })}
            </ul>
            <ul>
              {dataConsulting.item.map((res, idx) => {
                return (
                  <li key={idx} className="main-contents-item">
                    <div className="contents-img">
                      <span className="background">
                        <h2>{res.name}</h2>
                      </span>
                      <div>{res.itemImg}</div>
                    </div>
                    <ul className="contents-description-ul">
                      {res.itemDescription.map((res2, idx2) => {
                        return (
                          <li key={idx2}>
                            <p>{res2}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </article>
          <article className="main-contents-body">
            <h1 className="titleDesign">ITO/SI</h1>
            <ul className="itosi-ul">
              {dataItoSi.item.map((res, idx) => {
                return (
                  <li key={idx} className="main-contents-item">
                    <div className="icon-logo">{res.icon}</div>
                    <div className="contents-img">
                      <span className="background">
                        <h2>{res.name[0]}</h2>
                        {res.name[1] && <h2>{res.name[1]}</h2>}
                      </span>
                      <div>{res.itemImg}</div>
                    </div>
                    <ul className="contents-description-ul">
                      {res.itemDescription.map((res2, idx2) => {
                        return (
                          <li key={idx2}>
                            <p>{res2}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
            <ul className="itosi-ul itosi-ul-2">
              {dataItoSi.item2.map((res, idx) => {
                return (
                  <li key={idx} className="main-contents-item">
                    <div className="icon-logo">{res.icon}</div>
                    <div className="contents-img">
                      <span className="background">
                        <h2>{res.name[0]}</h2>
                        {res.name[1] && <h2>{res.name[1]}</h2>}
                      </span>
                      <div>{res.itemImg}</div>
                    </div>
                    <ul className="contents-description-ul">
                      {res.itemDescription.map((res2, idx2) => {
                        return (
                          <li key={idx2}>
                            <p>{res2}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </article>
        </section>
      </div>
    </BusinessItMainStyle>
  );
};

const BusinessItSolution = () => {
  const up1 = useRef(null);
  const options = {
    root: null,
    rootMargin: "-10%",
    threshold: 0,
  };
  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      e.map((f) => {
        return f.isIntersecting && f.target.classList.add("up");
      });
    }, options);
    up1.current && observer.observe(up1.current);
  });
  return (
    <>
      <BusinessItSolutionStyle className="no-select">
        <BusinessItSolutionMain />
        <BusinessItMain />
      </BusinessItSolutionStyle>
    </>
  );
};

export default BusinessItSolution;
