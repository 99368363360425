import styled from "styled-components";

interface widthInterface {
  w?: number;
}
const navList = {
  div2: [
    {
      key: "Address",
      value:
        "195/5 Lake Rajada Office Complex Tower 2, 4th Floor, Rajadapisek Road, Klongtoey Sub-district, Klongtoey district, Bangkok 10110",
    },
    { key: "E-Mail", value: "hr@scgs.co.th" },
    { key: "Tel", value: "02-119-7570" },
  ],
  div3: [
    {
      key: " ",
      value: "SCG Solutions (Thailand) Ltd. All Rights Reserved.",
    },
  ],
};
const FooterStyle = styled.ul<widthInterface>`
  background: #001133;
  color: #959aa3;
  margin-top: auto;
  font-size: 13px;
  .footer-box {
    padding: 10px 0px;
    max-width: 1200px;
    width: 95%;
    margin: auto;
    display: flex;
    .footer-left {
      width: 100%;
      .footer-contents {
        display: flex;
        li {
          p {
            position: relative;
            a {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            }
          }
        }
        .logo-area {
          height: 30px;
          display: flex;
          a {
            height: 100%;
            aspect-ratio: 1;
            svg {
              width: 100%;
              height: auto;
            }
          }
        }
        .footer-select {
          display: flex;
          select {
            margin: auto 0;
            cursor: pointer;
            background: none;
            color: #959aa3;
          }
        }
        &.footer-between {
          justify-content: space-between;
        }
        & + div {
          margin-top: 10px;
        }
        &:nth-of-type(1) {
          ul {
            display: flex;
            li + li {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  li {
    position: relative;
  }
  a {
    transition: 0.3s;
    display: flex;
    path {
      transition: 0.3s;
    }
    &:hover {
      path {
        transition: 0.1s;
      }
      g {
        path {
          fill: #032b79;
        }
      }
      path {
        fill: #fff;
      }
      transition: 0.1s;
      color: #fff;
    }
  }
  @media (max-width: 768px) {
    .footer-box {
      padding: 20px 5px;
      display: flex;
      .footer-left {
        .footer-contents {
          display: flex;
          flex-wrap: wrap;
          li {
            p {
              margin-right: 5px;
            }
          }
          &.footer-between {
            justify-content: unset;
            ul {
              display: flex;
              flex-wrap: wrap;
              & + ul {
                margin-left: 0px;
              }
            }
          }
          li + li {
            margin-left: 0px;
          }
          li {
            margin-top: 5px;
          }
          &:first-child {
          }
        }
        .logo-area {
          margin-right: 5px;
        }
      }
    }
    li {
      position: relative;
    }
    a {
      transition: 0.3s;
      display: flex;
      path {
        transition: 0.3s;
      }
      &:hover {
        path {
          transition: 0.1s;
        }
        g {
          path {
            fill: #032b79;
          }
        }
        path {
          fill: #fff;
        }
        transition: 0.1s;
        color: #fff;
      }
    }
  }
`;
const Footer = () => {
  return (
    <FooterStyle className="no-select">
      <div className="footer-box">
        <div className="footer-left">
          <div className="footer-contents footer-between">
            <ul>
              <li className="footer-select">
                <select
                  defaultValue=""
                  onChange={(e) => window.open(e.target.value)}
                >
                  <option value="" disabled>
                    SCGS FAMILY
                  </option>
                  <option value={"http://www.scgs.co.kr"}>SCGS KOREA</option>
                  <option value={"http://scgsseagate.co.kr"}>Seagate</option>
                  <option value={"http://salesforce.scgs.co.kr"}>SFDC</option>
                  <option value={"http://okdell.com"}>OKDELL</option>
                </select>
              </li>
            </ul>
          </div>
          <div className="footer-contents footer-between">
            <ul>
              {navList.div2.map((res, idx) => {
                return (
                  <li key={idx}>
                    <p>
                      {res.key} : {res.value}
                      {idx === 1 && <a href={`mailto:${res.value}`} />}
                      {idx === 2 && <a href={`tel:${res.value}`} />}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="footer-contents">
            <li>
              <p>SCG Solutions (Thailand) Ltd. All Rights Reserved.</p>
            </li>
          </div>
        </div>
      </div>
    </FooterStyle>
  );
};

export default Footer;
