import { debounce } from "lodash";
import { useEffect, useState } from "react";
import RatioCheck from "./RatioResize";

export default function WindowHeight() {
  const [vh, setVh] = useState(
    typeof window !== "undefined" && window.innerHeight * 0.01
  );
  const handleResize = debounce(() => {
    setVh(window.innerHeight * 0.01);
    RatioCheck();
  }, 10);
  typeof document !== "undefined" &&
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
}
