import styled from "styled-components";
import { motion } from "framer-motion";
import Icons from "../assets/icons";
import { useEffect, useRef } from "react";

const contentsData = [
  {
    title: "Group insurance",
    icon: <Icons.IconAccident2 />,
  },
  {
    title: "Provident Fund",
    icon: <Icons.IconBusiness2 />,
  },
  {
    title: "Yearly outing",
    icon: <Icons.IconWorker2 />,
  },
];
const RecruitStyle = styled.ul`
  .scroll {
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    position: relative;
  }
  .middle-title {
    font-size: 30px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 60px;
    color: #2d59a2;
  }
  .article-title {
    padding: 10px 30px;
    margin-top: 30px;
    background-color: #2d59a2;
    color: #fff;
    width: fit-content;
  }
  .button-area {
    margin: 50px auto;
    display: flex;
    width: 95%;
    max-width: 1200px;
    border-bottom: 2px solid #2d59a2;
    .button {
      font-size: 24px;
      min-width: 200px;
      width: 100%;
      text-align: center;
      height: 60px;
      display: flex;
      font-weight: bold;
      transition: 0.3s;
      p {
        margin: auto;
      }
    }
    .button.active {
      color: #fff;
      background: #2d59a2;
    }
  }
  .titleDesign {
    font-size: 30px;
    color: #2d59a2;
    display: flex;
    border-bottom: 1px solid #2d59a2;
    margin-bottom: 30px;
    ::before {
      content: "";
      width: 6px;
      height: 22px;
      margin: auto 7px auto 0;
      background-color: #2d59a2;
    }
    aside {
      margin: auto 5px auto auto;
      padding: 3px 10px;
      font-size: 14px;
      font-weight: 400;
      border: 1px solid #2d59a2;
      transition: 0.3s;
      position: relative;
      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      ::after {
        transition: inherit;
        content: "";
        position: absolute;
        width: 0%;
        height: 100%;
        background-color: #2d59a2;
        top: 0;
        left: 0;
        z-index: 0;
      }
      :hover {
        ::after {
          width: 100%;
        }
        p {
          color: #fff;
        }
      }
      p {
        position: relative;
        transition: inherit;
        margin: auto;
        z-index: 1;
      }
    }
  }
  @media (max-width: 768px) {
    .middle-title {
      font-size: 20px;
      margin-bottom: unset;
      margin: 40px 5px;
      word-break: keep-all;
    }
    .button-area {
      .button {
        font-size: 16px;
        min-width: unset;
        height: 36px;
      }
      .button.active {
        color: #fff;
        background: #2d59a2;
      }
    }
  }
`;

const RecruitMainStyle = styled.div`
  .scroll {
    min-height: 600px;
  }
  .banner-image {
    background: url("/background/recruit-3.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    .banner-text {
      margin: auto;
      background: #ffffff99;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      h1 {
        font-size: 60px;
        margin: auto;
        line-height: 1em;
      }
    }
  }
  @media (max-width: 768px) {
    .scroll {
      min-height: 400px;
    }
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const RecruitMain = () => {
  return (
    <RecruitMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleX(0)", opacity: 0 }}
          animate={{ transform: "scaleX(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            Recruit
          </motion.h1>
        </motion.div>
      </div>
    </RecruitMainStyle>
  );
};
const RecruitInfoStyle = styled.div`
  .scroll {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: unset;
    max-width: 1200px;
    width: 95%;
    margin: 0 auto;
    ul > h1 {
      text-align: center;
      padding: 30px 0;
      background: #fff;
      font-size: 30px;
    }
  }
  .recruit-box {
    width: 100%;
    padding: 0px 0;
    h2 {
      font-size: 20px;
      display: flex;
      border-radius: 3px;
    }
    .recruit-info-top {
      .process {
        width: 100%;
        margin: auto;
        li + div {
          margin: auto 10px;
        }
        li {
          background-image: url("/background/recruit-03-en.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 300px;
        }
        .recruit-arrow-r {
          display: flex;
          svg {
            path {
              stroke: #026;
            }
          }
        }
      }
    }
    .recruit-info-bottom {
      display: flex;
      & > div + div {
        margin-left: 20px;
      }
      .recruit-info-bottom-left {
        width: 100%;
        div:first-child {
          min-width: 100px;
        }
      }
      .recruit-info-bottom-right {
        width: 100%;
        .partners-bottom {
          margin: 30px 0 70px;
          .partners-bottom-box {
            display: flex;
            &:nth-of-type(1) {
              border-bottom: 2px solid #fff;
            }
            > h2 {
              min-width: 200px;
              background: #4699f9;
              color: #fff;
              min-height: 50px;
              display: flex;
              border-radius: unset;
              justify-content: space-between;
              position: relative;
              span {
                margin: auto 0;
                text-indent: 1em;
              }
              b {
                width: 30px;
                aspect-ratio: 1;
                margin: auto 20px;
                font-size: 0;
                display: flex;
                svg {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            > p {
              text-align: left;
              padding: 0 20px;
              display: flex;
              min-width: 220px;
              span {
                width: 100%;
                margin: auto 0;
              }
            }
            .button2 {
              width: fit-content;
              background-color: none;
              border: 1px solid #2d59a2;
              display: flex;
              height: fit-content;
              margin: auto 0;
              padding: 5px 20px;
              position: relative;
              transition: 0.3s;
              border-radius: 5px;
              ::after {
                content: "";
                position: absolute;
                z-index: -1;
                background-color: #2d59a2;
                width: 0%;
                height: 100%;
                left: 0%;
                top: 0%;
                transition: 0.3s;
              }
              :hover {
                color: #fff;
                ::after {
                  content: "";
                  position: absolute;
                  z-index: -1;
                  background-color: #2d59a2;
                  width: 100%;
                  height: 100%;
                  left: 0%;
                  top: 0%;
                }
                svg {
                  path {
                    fill: #fff;
                  }
                }
              }
              p {
                padding: 0;
                margin: auto;
                display: flex;
                svg {
                  path {
                    fill: #000;
                    transition: 0.3s;
                  }
                }
              }
            }
          }
        }
      }
      ul {
        li {
          display: flex;
          div {
            padding: 5px 10px;
            &:first-child {
              font-weight: bold;
            }
          }
        }
      }
    }
    .inner-box > div + div {
      margin-top: 50px;
    }
  }
  .inner-box {
    margin: auto;
  }
  .button {
    display: flex;
    text-align: center;
    color: #fff;
    transition: 0.3s;
    box-shadow: 2px 2px 5px 0 #00000022;
    border-radius: 3px;
    background: #05f;
    justify-content: center;
    font-size: 16px;
    margin-left: 20px;
    position: relative;
    p {
      margin: auto 0;
      display: flex;
    }
    &:hover {
      transition: 0.1s;
      background: #16f;
    }
    &:active {
      transition: 0.1s;
      background: #04c;
      box-shadow: 0px 0px 2px 0 #00000022;
      transform: translate(1px, 1px);
    }
  }
  a {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0%;
  }
  @media (max-width: 768px) {
    text-align: center;
    .recruit-box {
      h2 {
        padding: 2px 5px;
        text-align: left;
        font-size: 18px;
      }
      .recruit-info-top {
        h1 {
          font-size: 20px;
        }
        .process {
          li {
            height: 150px;
          }
        }
      }
      .recruit-info-bottom {
        display: unset;
        & > div + div {
          margin-left: unset;
          margin-top: 30px;
        }
        .recruit-info-bottom-right {
          .partners-bottom {
            margin: 10px 0 10px;
            .partners-bottom-box {
              > h2 {
                min-height: 36px;
                min-width: 100px;
                font-size: 14px;
                span {
                  text-indent: unset;
                  font-size: 12px;
                }
                b {
                  width: 20px;
                  margin: auto 0px;
                }
              }
              > p {
                min-height: 36px;
                font-size: 14px;
                padding: 0 10px;
              }
              .button2 {
                width: fit-content;
                background-color: none;
                border: 1px solid #2d59a2;
                display: flex;
                height: fit-content;
                margin: auto 0;
                padding: 5px 20px;
                position: relative;
                transition: 0.3s;
                border-radius: 5px;
                ::after {
                  content: "";
                  position: absolute;
                  z-index: -1;
                  background-color: #2d59a2;
                  width: 0%;
                  height: 100%;
                  left: 0%;
                  top: 0%;
                  transition: 0.3s;
                }
                :hover {
                  color: #fff;
                  ::after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    background-color: #2d59a2;
                    width: 100%;
                    height: 100%;
                    left: 0%;
                    top: 0%;
                  }
                  svg {
                    path {
                      fill: #fff;
                    }
                  }
                }
                p {
                  padding: 0;
                  margin: auto;
                  display: flex;
                  svg {
                    path {
                      fill: #000;
                      transition: 0.3s;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .inner-box > div + div {
        margin-top: unset;
      }
    }
  }
`;
const RecruitInfo = () => {
  const up1 = useRef(null);
  const options = {
    root: null,
    rootMargin: "-10%",
    threshold: 0,
  };
  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      e.map((f) => {
        return f.isIntersecting && f.target.classList.add("up");
      });
    }, options);
    [up1].forEach((ref) => ref.current && observer.observe(ref.current));
  });
  return (
    <RecruitInfoStyle>
      <div className="scroll">
        <ul>
          <li className="recruit-box">
            <div className="inner-box up-soon" ref={up1}>
              <div className="recruit-info-top">
                <h1 className="titleDesign">
                  <p>Process</p>
                </h1>
                <ul className={`process`}>
                  <li></li>
                </ul>
              </div>
              <div className="recruit-info-bottom">
                <div className="recruit-info-bottom-right">
                  <div className="partners-bottom">
                    <div className="partners-bottom-box">
                      <h2>
                        <span>E-mail Apply</span>
                      </h2>
                      <p style={{ position: "relative" }}>
                        <span>
                          hr@scgs.co.th
                          <a href="mailto:hr@scgs.co.th"></a>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </RecruitInfoStyle>
  );
};

const RecruitWelfareStyle = styled.div`
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  .background {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff99;
    top: 0;
    left: 0;
  }
  .scroll {
    position: relative;
    z-index: 1;
    min-height: unset;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: auto auto 20px;
    width: 96%;
  }
  .recruit-welfare-head {
    margin-bottom: 50px;
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 30px;
      margin-top: 20px;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    max-width: 690px;
    width: 100%;
    margin: auto;
    .welfare {
      width: calc((100% / 3) - 6px);
      min-width: 150px;
      aspect-ratio: 1/1;
      display: flex;
      flex-direction: column;
      background: #004c59b3;
      color: #fff;
      padding: 20px 0px;
      margin: 3px;
      justify-content: center;
      transition: 0.1s;
      line-height: 1.6em;
      padding: 10px;
      :hover {
        width: calc(100% / 3);
        margin: 0px;
        svg {
          transform: scale(1.1);
        }
      }
      span {
        height: 60px;
        width: fit-content;
        display: flex;
        aspect-ratio: 1;
        margin: 0 auto;
        svg {
          path {
            fill: #fff;
          }
          width: auto;
          height: auto;
          transition: 0.3s;
        }
      }
      h2 {
        font-size: 30px;
        margin-top: 20px;
      }
      p {
        font-size: 16px;
        height: 24px;
      }
    }
  }

  @media (max-width: 768px) {
    .scroll {
      padding: 30px 5px;
    }
    .titleDesign {
      font-size: 20px;
    }
    ul {
      margin: auto;
      .welfare {
        line-height: unset;
        span {
          height: 36px;
        }
        h2 {
          font-size: 16px;
          margin-top: 10px;
          line-height: 1.2em;
        }
        p {
          height: 24px;
        }
      }
      .recruit-box {
        width: calc(100% / 3 - 4px);
        min-width: unset;
        aspect-ratio: unset;
        padding: 20px 10px;
        margin: 5px auto;
        pointer-events: none;
      }
    }
  }
`;
const RecruitWelfare = () => {
  const up1 = useRef(null);
  const options = {
    root: null,
    rootMargin: "-10%",
    threshold: 0,
  };
  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      e.map((f) => {
        return f.isIntersecting && f.target.classList.add("up");
      });
    }, options);
    [up1].forEach((ref) => ref.current && observer.observe(ref.current));
  });
  return (
    <RecruitWelfareStyle>
      <div className="scroll">
        <h1 className="titleDesign">
          <p>Benefits</p>
        </h1>
        <ul className="up-soon" ref={up1}>
          {contentsData.map((res, idx) => {
            return (
              <li key={idx} className="recruit-box welfare">
                <span>{res.icon}</span>
                <h2>{res.title}</h2>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="background"></div>
    </RecruitWelfareStyle>
  );
};
const Recruit = () => {
  return (
    <RecruitStyle className="no-select">
      <RecruitMain />
      <h1 className="middle-title">
        We welcome passionate individuals ready to grow together with SCG
        Solutions!
      </h1>
      <RecruitInfo />
      <RecruitWelfare />
    </RecruitStyle>
  );
};

export default Recruit;
