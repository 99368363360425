import { useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import RatioCheck from "../utils/RatioResize";
import SlideComponent from "./SlideComponent";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { debounce } from "lodash";

const TextList = [
  {
    class: "main-intro",
    translation: ["SCGS", "Thailand"],
    link: ["/introduce"],
  },
  {
    class: "main-it",
    translation: ["IT", "Business"],
    link: ["/business/it"],
  },
  {
    class: "main-solar",
    translation: ["Renewable Energy", "Business"],
    link: ["/business/solar"],
  },
];

const MainComponentStyle = styled.ul`
  .scroll {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100 - 50px);
    margin: auto;
    overflow: hidden;
    position: relative;
  }
`;

const MainVideoStyle = styled.div`
  .main-video {
    background: #12363b;
    position: relative;
    .videoSize {
      width: 100%;
      height: 100%;
      video {
        width: 100vw;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      }
    }
    .videoText {
      position: absolute;
      top: 0;
      left: 0;
      background: #00000066;
      color: #fff;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;
      text-align: center;
      .slide-style {
        word-break: keep-all;
        max-width: 1100px;
        margin: auto;
        h1 {
          line-height: 1.1em;
          font-size: 60px;
        }
        p {
          font-size: 40px;
          margin-top: 20px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .main-video {
      .videoText {
        .slide-style {
          max-width: 510px;
          width: 95%;
          h1 {
            font-size: 30px;
          }
          p {
            font-size: 20px;
            margin-top: 10px;
          }
          .slick-dots {
            background-color: #fff;
          }
        }
      }
    }
  }
`;
const MainVideo = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    videoRef.current &&
      videoRef.current.addEventListener("resize", () => {
        RatioCheck();
      });
  }, [videoRef]);

  const settings = {
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    pauseOnHover: true,
  };

  return (
    <MainVideoStyle>
      <div
        ref={videoBoxRef}
        className="scroll banner-image main-video check-ratio"
      >
        <div className="videoSize">
          <video ref={videoRef} loop autoPlay muted src="/video/main.mp4" />
        </div>
        <div className="videoText">
          <SlideComponent settings={settings}>
            <div className="slide-style">
              <motion.h1
                initial={{ translateY: "-50px", opacity: 0 }}
                animate={{ translateY: "0", opacity: 1 }}
                transition={{ ease: "easeInOut", duration: 1 }}
              >
                No1. End to End Solution Provider SCGS Thailand
              </motion.h1>
            </div>
            <div className="slide-style">
              <motion.h1
                initial={{ translateY: "-50px", opacity: 0 }}
                animate={{ translateY: "0", opacity: 1 }}
                transition={{ ease: "easeInOut", duration: 1 }}
              >
                We succeed together with our business partners<br></br>
                <p>Customer value is our top priority</p>
              </motion.h1>
            </div>
          </SlideComponent>
        </div>
      </div>
    </MainVideoStyle>
  );
};

const MainBusinessStyle2 = styled.div`
  position: relative;
  color: #fff;
  text-align: center;
  background: url("/intro_line_extend.svg") center bottom / contain no-repeat;
  .scroll {
    max-width: 1200px;
    width: 95%;
    display: flex;
    max-height: calc(var(--vh, 1vh) * 100 - 50px - 135px);
  }
  h1 {
    font-size: 30px;
    background-color: #061321;
    z-index: 1;
    word-break: keep-all;
  }
  ul {
    width: 100%;
    margin: auto;
    display: flex;
    height: calc(100% - 100px);
    max-height: 700px;
    &:hover {
      article {
        filter: grayscale();
        opacity: 0.5;
      }
      article:hover {
        filter: unset;
        opacity: 1;
        width: 100%;
        header p {
          width: 70px;
        }
      }
    }
    article {
      height: calc(100%);
      width: 50%;
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: 100vw auto;
      transition: 0.3s;
      display: flex;
      flex-direction: column;
      position: relative;
      a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
      }
      header {
        margin-top: 90px;
        p {
          transition: 0.3s;
          font-size: 0;
          width: 50px;
          margin: 0 auto;
        }
        h2 {
          transition: 0.3s;
          font-size: 33px;
          max-width: 320px;
          margin: 0 auto;
          word-break: keep-all;
        }
      }
      section {
        p {
          transition: 0.3s;
          span + span {
            &::before {
              content: "ㆍ";
            }
          }
        }
      }
    }
    .main-it {
      background-color: rgb(65, 53, 70);
      background-image: linear-gradient(
          to top,
          rgba(53, 56, 70, 0) 0%,
          rgba(53, 56, 70, 1) 70%
        ),
        url("/background/network.jpg");
    }
    .main-solar {
      background-color: rgb(2, 84, 134);
      background-image: linear-gradient(
          to top,
          rgba(2, 84, 134, 0) 0%,
          rgba(2, 84, 134, 1) 70%
        ),
        url("/background/solar2.jpg");
    }
    .main-intro {
      background-color: rgb(5, 14, 70);
      background-image: linear-gradient(
          to top,
          rgba(5, 14, 70, 0) 0%,
          rgba(5, 14, 70, 1) 70%
        ),
        url("/background/business-ito.jpg");
    }
    .net-1,
    .solar-1,
    .solar-2,
    .manage-1 {
      stroke: #fff;
    }
    .cs-1,
    .cs-2 {
      stroke: #000;
    }
  }
  button {
    display: flex;
    margin: 20px auto 0;
    border: 1px #ffffff solid;
    background: #ffffff66;
    border-radius: 2px;
    color: #fff;
    transition: 0.3s;
    a {
      padding: 2px 20px;
    }
    &:hover {
      background: #ffffff;
      color: #000;
    }
  }
  @media (max-width: 768px) {
    .scroll {
      margin: 30px auto;
      height: unset;
      max-height: unset;
    }
    h1 {
      padding: 20px 0;
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
      z-index: unset;
      color: #000;
    }
    ul {
      flex-direction: column;
      max-height: unset;
      &:hover {
        article {
          filter: unset;
          opacity: unset;
        }
        article:hover {
          width: unset;
        }
      }
      article {
        width: 100%;
        margin-bottom: 2.5%;
        background-position: center;
        background-size: cover;
        header {
          margin-top: unset;
          min-height: 150px;
          height: calc((200px));
          display: flex;
          flex-direction: column;
          justify-content: center;
          h2 {
            font-size: 25px;
            max-width: unset;
          }
        }
      }
    }
  }
`;
const MainBusiness2 = () => {
  return (
    <MainBusinessStyle2>
      <div className="scroll">
        <ul>
          {TextList.map((res, idx) => {
            return (
              <article className={`${res.class}`} key={idx}>
                <header>
                  <h2>{res.translation[0]}</h2>
                  <h2>{res.translation[1]}</h2>
                </header>
                <Link to={res.link[0]}></Link>
              </article>
            );
          })}
        </ul>
      </div>
    </MainBusinessStyle2>
  );
};

const MainComponent = () => {
  const main = useRef(null);
  const up1 = useRef(null);
  const target = useRef(null);
  const options = {
    root: null,
    rootMargin: "-10%",
    threshold: 0,
  };

  let idx = 0;
  const WheelEventHandler = useCallback((e: any) => {
    if (e.deltaY > 0) {
      if (idx < 3) {
        idx++;
      }
      window.document.querySelector(".body")?.scrollTo({
        top: Number(window.document.querySelector(".body")?.clientHeight) * idx,
        behavior: "smooth",
      });
    } else {
      if (idx > 0) {
        idx--;
      }
      window.document.querySelector(".body")?.scrollTo({
        top: Number(window.document.querySelector(".body")?.clientHeight) * idx,
        behavior: "smooth",
      });
    }
  }, []);

  const throttledWheel = debounce((e) => {
    WheelEventHandler(e);
  }, 200);

  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      e.map((f) => {
        return f.isIntersecting && f.target.classList.add("up");
      });
    }, options);
    [up1].forEach((ref) => ref.current && observer.observe(ref.current));
  });

  return (
    <MainComponentStyle
      onWheel={(e) =>
        Number(window.document.querySelector(".body")?.clientWidth) > 768 &&
        throttledWheel(e)
      }
      className="no-select"
      ref={main}
    >
      <section ref={target}>
        <MainVideo />
      </section>
      <section className="up-soon" ref={up1}>
        <MainBusiness2 />
      </section>
    </MainComponentStyle>
  );
};

export default MainComponent;
